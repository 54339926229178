import { StrategyFieldValue, StrategyMatrix, StrategyMatrixValue, StrategyMatrixValueItem } from '@type/strategy.type';

export function convertMatrixToData(matrix: StrategyMatrix, branchId: number | string): StrategyMatrixValue {
  return Object.keys(matrix).reduce((strategyMatrixValue, key) => {
    const value = matrix[key][Number(branchId)];
    strategyMatrixValue.add(key, new StrategyMatrixValueItem(value));

    return strategyMatrixValue;
  }, new StrategyMatrixValue());
}

function getBaseFieldName(field: StrategyFieldValue, empty: string) {
  return field.field_type !== 'boolean' || field.b_value === null ?
      field.field_reference_name || empty + ' ' + field.field_localized_name
    : field.field_localized_name;
}

export function createFieldName(
  field: StrategyFieldValue,
  translations: {
    yes: string;
    no: string;
    empty: string;
  },
) {
  return `${getBaseFieldName(field, translations.empty)}${
    field.field_type === 'boolean' ?
      field.b_value === null ? ''
      : field.b_value === true ? ': ' + translations.yes
      : ': ' + translations.no
    : ''
  }`;
}
